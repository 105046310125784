import classes from "./Select.module.scss";
import { IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';


const Select = (props) => {
  const cls = [
    classes.select_container,
    props.clsType ? classes[props.clsType] : "",
  ];

  

  // when sorting options, place these labels at the top
  const high_priority_labels = [
    "CFR Premium/Payroll",
    "CFR Payroll Instant Issue",
    "CFR VITA",
    ...props.high_priority_labels || []
  ].filter(v => props.high_priority_overrides ? !props.high_priority_overrides.includes(v) : true).filter(v => {
    if(props.label && ['location:', 'locations:'].includes(props.label.toLocaleLowerCase())) {
      return v !== "CFR VITA";
    }
    return true;
  });

  // console.log(props.label, high_priority_labels)

  const dont_sort = props.dont_sort || false;
  const first_element_is_placeholder = props.first_element_is_placeholder === undefined ? true : props.first_element_is_placeholder;

  /**
   * 
   * @param {{value: string, label: string}[]} opts 
   * @returns 
   */
  function toSorted(opts) {
    if(dont_sort) return opts;
    const sortFn = (a, b) => {
      if (high_priority_labels.includes(a.label) && !high_priority_labels.includes(b.label)) {
        return -1;
      }
      if (high_priority_labels.includes(b.label) && !high_priority_labels.includes(a.label)) {
        return 1;
      }
      if(typeof(a.label) == "number" && typeof(b.label) == "number") {
        return a.label - b.label;
      }
      return ("" + a.label).localeCompare(("" + b.label));
    }
    
    if(first_element_is_placeholder) {
      const first_element = opts[0];  // keep the first element as is
      if(!first_element) return opts;
      opts = opts.slice(1);

      return [first_element, ...opts.sort(sortFn)];
    }else {
      return opts.sort(sortFn);
    }
    
  }
  
  const options = toSorted(props.options || []);

  if (props.hasError) {
    cls.push(classes.invalid);
  }

  return (
    <div className={cls.join(" ")}>
      {props.label && (
        <label>
          {props.label}{" "}
          {props.hasError ? (
            <span className={classes.select__error}>
              {props.errorMessageProvider ? props.errorMessageProvider() : props.errorMessage || "Enter valid data"}
            </span>
          ) : null}
        </label>
      )}

      <div className={classes.select + (props.value === "" ? " " + classes.placeholder : "") + " " + props.outerDivClassName}>
        <select
          ref={props.ref}
          className={props.className}
          onChange={props.onChange}
          disabled={props.disabled}
          value={props.value}
          id={props.id}
        >
          {!props.disabled && options.map((option) => (
            (option.hasOwnProperty("visible") ? option.visible : true) &&
            <option
              key={option.value}
              value={option.value}
              disabled={option.isDisabled}
            >
              {option.label} {props.value === option.value}
            </option>
          ))}
        </select>

        <div className={classes.select_icon}>
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="dropdown-heading-dropdown-arrow gray"
          >
            <path d="M6 9L12 15 18 9"></path>
          </svg>
        </div>
      </div>

      {props.cancel && (
        <IconButton className={classes.Icon__cancel} aria-label="cancel" onClick={props.cancel}>
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Select;
