import moment from "moment";
import { Moment } from "moment";

/**
 * Perform action if date is valid
 * @param date string date
 * @param fn the action that will be performed if the date is valid
 * @returns the date or the passed in string date
 */
export function ifValidDate(date: string, fn: (date: Moment) => Moment): string | Moment {
  const d = moment(date);
  if(d.isValid()) {
      return fn(d);
  }
  return d;
}