import React, { useRef, useState } from 'react';
import {
  CustomFilterItem,
  DateFilterItem,
  DropdownOption,
  FilterItem,
  InputFilterItem,
  NonReferencedSelectFilterItem,
  ReferencedSelectFilterItem,
  SelectFilterItem,
  ToggleFilterItem,
} from './FilterState';
import moment from 'moment';

export class FilterItemBuilder {
  public filterItem: FilterItem;

  constructor() {
    this.filterItem = {visible: true} as FilterItem;
  }

  setType(type: 'phone' | 'string' | 'number' | 'date' | 'custom'): FilterItemBuilder {
    this.filterItem.type = type;
    return this;
  }

  setHidden() {
    this.filterItem.visible = false;
    return this;
  }

  select() {
    this.filterItem.type = 'select';
    return new SelectFilterItemBuilder(this);
  }

  date() {
    this.filterItem.type = 'date';
    return new DateFilterItemBuilder(this);
  }

  checkbox() {
    this.filterItem.type = 'toggle';
    return new CheckboxFilterItemBuilder(this);
  }

  setErrorMessage(value: string) {
    this.filterItem.error_message_provider = () => value;
    return this;
  }

  setErrorMessageProvider(value: (v: string) => string) {
    this.filterItem.error_message_provider = value;
    return this;
  }

  setLabel(label: string): FilterItemBuilder {
    this.filterItem.label = label;
    return this;
  }

  setPermanent() {
    this.filterItem.non_removable = true;
    return this;
  }

  setParamName(paramName: string): FilterItemBuilder {
    this.filterItem.paramName = paramName;
    return this;
  }

  custom() {
    this.filterItem.type = 'custom';
    return new CustomFilterItemBuilder(this);
  }

  setPlaceholder(placeholder: string): FilterItemBuilder {
    if (
      this.filterItem.type === 'string' ||
      this.filterItem.type === 'number' ||
      this.filterItem.type === 'phone' ||
      this.filterItem.type === 'date'
    ) {
      (this.filterItem as InputFilterItem).placeholder = placeholder;
    }
    return this;
  }

  build(): FilterItem {
    return this.filterItem;
  }

  useBuildCombo(
    useInputValidator: (value: string | null) => boolean,
    _useInput,
    value?: string
  ) {
    const built = this.build();

    return {
      item: built,
      value: FilterItemBuilder.buildValue(
        built,
        useInputValidator,
        _useInput,
        value
      ),
    };
  }

  static buildValue(
    filterItem: FilterItem,
    useInputValidator: (value: string | null) => boolean,
    _useInput,
    value?: string
  ) {
    return _useInput(useInputValidator, value, filterItem.type);
  }
}

export class CheckboxFilterItemBuilder {
  protected filterItem: ToggleFilterItem;

  constructor(old: FilterItemBuilder) {
    this.filterItem = old.filterItem as ToggleFilterItem;
  }

  startEnabled() {
    this.filterItem.start_enabled = true;
    return this;
  }

  startDisabled() {
    this.filterItem.start_enabled = false;
    return this;
  }

  build(): ToggleFilterItem {
    return this.filterItem;
  }

  useBuildCombo(_useState) {
    const built = this.build();
    return {
      item: built,
      value: CheckboxFilterItemBuilder.buildValue(built, _useState),
    };
  }

  static buildValue(filterItem: ToggleFilterItem, _useState) {
    return _useState(filterItem.start_enabled);
  }
}

export class SelectFilterItemBuilder {
  protected filterItem: SelectFilterItem;

  constructor(old: FilterItemBuilder) {
    this.filterItem = old.filterItem as SelectFilterItem;
  }

  setReference() {
    this.filterItem.reference = true;
    return this;
  }

  setOptions(options: DropdownOption[]) {
    if (!this.filterItem.reference) {
      (this.filterItem as NonReferencedSelectFilterItem).options = options;
    } else {
      throw new Error(
        'Options can only be set for non-referenced select types'
      );
    }
    return this;
  }

  setGetterFunc(
    getterFunc: () => Promise<{ [key: string]: string }[]>
  ) {
    if (this.filterItem.reference) {
      (this.filterItem as ReferencedSelectFilterItem).getterFunc = getterFunc;
    } else {
      throw new Error(
        'Getter function can only be set for referenced select types'
      );
    }
    return this;
  }

  setValueAndLabelParams(valueParam: string, labelParam: string) {
    if (this.filterItem.reference) {
      const refSelectFilterItem = this.filterItem as ReferencedSelectFilterItem;
      refSelectFilterItem.valueParam = valueParam;
      refSelectFilterItem.labelParam = labelParam;
    }
    return this;
  }

  build(): SelectFilterItem {
    return this.filterItem;
  }

  useBuildCombo(reducer, initialState, useReducer) {
    return {
      item: this.build(),
      value: SelectFilterItemBuilder.buildValue(
        reducer,
        initialState,
        useReducer
      ),
    };
  }

  static buildValue(reducer, initialState, _useReducer) {
    let r = _useReducer(reducer, initialState);
    return {
      state: r[0],
      dispatch: r[1],
    };
  }
}

export class DateFilterItemBuilder {
  protected filterItem: DateFilterItem;

  constructor(old: FilterItemBuilder) {
    this.filterItem = old.filterItem as DateFilterItem;
    this.filterItem.snapTo = 'none';
  }

  startDate() {
    this.filterItem.snapTo = 'startOfDay';
    return this;
  }

  endDate() {
    this.filterItem.snapTo = 'endOfDay';
    return this;
  }

  build() {
    return this.filterItem;
  }

  useBuildCombo(
    useInputValidator: (value: string | null) => boolean,
    _useInput,
    value?: string
  ) {
    const built = this.build();

    return {
      item: built,
      value: FilterItemBuilder.buildValue(
        built,
        useInputValidator,
        _useInput,
        value
      ),
    };
  }
}

export class CustomFilterItemBuilder {
  protected filterItem: CustomFilterItem;

  constructor(old: FilterItemBuilder) {
    this.filterItem = old.filterItem as CustomFilterItem;
  }

  setComponent(component: React.FC) {
    this.filterItem.component = component;
    return this;
  }

  setDefaultState(state: any) {
    this.filterItem.defaultState = state;
    return this;
  }

  build() {
    return this.filterItem;
  }

  useBuildCombo(_useState) {
    const built = this.build();
    return {
      value: _useState(built.defaultState),
      item: built
    }
  }
}

export function useFilters(
  values: (filterValuesRef: { [key: string]: any } | null) => {
    [key: string]: { item: FilterItem; value: any };
  },
  defaultValues: string[]
) {
  const filterValuesRef = useRef<any>(null);

  const stateValuePair: {
    state: { [key: string]: FilterItem | string[]; activeFields: string[] };
    value: { [key: string]: any };
  } = {
    state: {
      activeFields: [],
    },
    value: {},
  };
  const resets: any[] = [];
  // filterValuesRef will always be null on first render.
  const computedValues = values(filterValuesRef.current);

  for (const key in computedValues) {
    const combo = computedValues[key];

    stateValuePair.state[key] = combo.item;
    stateValuePair.value[key] = combo.value;
  }

  const filterValueKeys = Object.keys(stateValuePair.value);
  for (const key of filterValueKeys) {
    if (stateValuePair.value[key].reset) {
      resets.push(stateValuePair.value[key].reset);
    } else {
      resets.push(() => {
        stateValuePair.value[key].dispatch({ type: 'RESET' });
      });
    }
  }

  stateValuePair.state.activeFields = defaultValues;

  filterValuesRef.current = stateValuePair.value;

  return {
    filterState: useState(stateValuePair.state),
    filterValues: stateValuePair.value,
    resets,
  };
}

export function getValues(filterState: any, filterValues: any) {
  const invalidList: string[] = [];
  const prepareBody = {};

  for (const key of filterState.activeFields) {
    if (filterState[key].type !== 'select') {
      if (filterState[key].type === 'toggle') {
        prepareBody[filterState[key].paramName] = filterValues[key][0];
      } else {
        if (!filterValues[key].isValid) {
          invalidList.push(key);
          continue;
        }
        if (filterValues[key].value !== '') {
          if (filterState[key].type === 'phone') {
            prepareBody[filterState[key].paramName] = filterValues[key].value
              .replace('(', '')
              .replace(')', '')
              .replace(' ', '')
              .replace('-', '');
          }else if(filterState[key].type === 'date') {
            var date = moment(filterValues[key].value);
            if(filterState[key].snapTo === 'startOfDay') {
              date.startOf('day');
            }
            if(filterState[key].snapTo === 'endOfDay') {
              date.endOf('day');
            }
            prepareBody[filterState[key].paramName] = date.valueOf();
          } else {
            prepareBody[filterState[key].paramName] = filterValues[key].value;
          }
        }
      }
    } else {
      if (filterValues[key].state.value !== '') {
        prepareBody[filterState[key].paramName] = filterValues[key].state.value;
      }
    }
  }

  return {
    wasValid: invalidList.length === 0,
    invalid_entries: invalidList,
    body: prepareBody,
  };
}
