import CBOSearch from '../components/CBOs/CBOSearch';
import CBOSearchTable from '../components/CBOs/CBOSearchTable';
import Heading from '../components/Heading/Heading.tsx';
import Layout from '../components/Layout/Layout';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import CBOModal from '../components/CBOs/CBOModal';
import WaitModal from '../components/Enroll/WaitModal';
import moment from "moment"
import { createCBOAdmin, deactivateCBOAdmin, getCBOAdmin, listCBOsForAdmin, updateCBOAdmin } from '../api/api';
import useNotification from '../hooks/useNotification';
import ReactDOM from "react-dom";
import Notification from '../components/UI/Notification/Notification';
import CBOActions from '../components/CBOs/CBOActions';

function CBOs() {
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const CBOData = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([])
    const [loadReason, setLoadReason] = useState()
    const [mostRecentSearchConditions, setMostRecentSearchConditions] = useState()
    const runBulkAction = useRef();

    const {
        status: notificationStatus,
        text: notificationText,
        btn_text: notificationBtnText,
        isShow: notificationIsShow,
        add: addNotification,
        remove: removeNotification,
    } = useNotification();

    function search(searchConditions) {
        const modifiedSearchConditions = {...searchConditions};
        if(searchConditions.enrollmentStartDate) {
            modifiedSearchConditions.enrollmentStartDate = moment(searchConditions.enrollmentStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
        }
        if(searchConditions.enrollmentEndDate) {
            modifiedSearchConditions.enrollmentEndDate = moment(searchConditions.enrollmentEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
        }
        // console.log(`ok`)
        setMostRecentSearchConditions(modifiedSearchConditions)
        listCBOsForAdmin(modifiedSearchConditions).then(list => {
            setListData(list);
        })
    }

    useEffect(() => {
        search({
            name: '',
            minQty: 0,
            minYTDEnrollments: 0,
            enrollmentStartDate: '',
            enrollmentEndDate: '',
            includeDeactivated: false,
        })
    }, [])

    

    /**
     * @param {string} id
     */
    const editCbo = useCallback((id) => {
        setLoadReason('Getting up to date info on CBO');
        setIsLoading(true);
        getCBOAdmin(id).then((cbo) => {
            setIsEditing(true);
            setIsLoading(false);
            setEditingId(id);
            CBOData.current = cbo;
        }).catch(() => {
            addNotification({
                status: "error",
                text: "Load failed! Please reload and try again",
                btn_text: "OK",
            });

            setTimeout(removeNotification, 5000);
            setIsLoading(false)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [addNotification, removeNotification])

    function updateCBOData(data) {
        setIsLoading(true);
        setLoadReason('Processing CBO Changes');

        updateCBOAdmin(editingId, data).then(() => {
            exitCboModal();
            setIsLoading(false);
            addNotification({
                status: "success",
                text: "All Changes succeeded!",
                btn_text: "OK",
            });
            setTimeout(removeNotification, 1000);
            search(mostRecentSearchConditions);
        }).catch((e) => {
            addNotification({
                status: "error",
                text: e.message,
                btn_text: "OK",
            });
            setIsLoading(false);
        })

        // exitCboEdit();
    }

    function exitCboModal() {
        setIsEditing(false);
        setIsAdding(false);
        setEditingId(null);
    }

    const deleteCBO = useCallback((id) => {
        setLoadReason('deactivating CBO');
        setIsLoading(true);
        removeNotification()
        deactivateCBOAdmin(id).then(res => {
            if(res.success) {
                addNotification({
                    status: "success",
                    text: "CBO deactivated",
                    btn_text: "OK",
                });
                setTimeout(removeNotification, 5000);
            }else {
                addNotification({
                    status: "error",
                    text: res.message,
                    btn_text: "OK",
                });
            }

            search(mostRecentSearchConditions);
        }).catch((e) => {
            addNotification({
                status: "error",
                text: e.message,
                btn_text: "OK",
            });
        }).finally(() => {
            setIsLoading(false);
        })
    }, [mostRecentSearchConditions, addNotification, removeNotification])

    function addCBO() {
        setIsAdding(true);
    }

    function createCBO(data) {
        setIsLoading(true);
        setLoadReason('Adding CBO to database');

        createCBOAdmin(data).then(() => {
            exitCboModal();
            setIsLoading(false);
            addNotification({
                status: "success",
                text: "All Changes succeeded!",
                btn_text: "OK",
            });
            setTimeout(removeNotification, 1000);
            search(mostRecentSearchConditions);
        }).catch((e) => {
            addNotification({
                status: "error",
                text: e.message,
                btn_text: "OK",
            });
            setIsLoading(false);
        })
    }


    // useEffectDebugger(() => {

    // }, [listData, deleteCBO, editCbo])

    const coolData = useMemo(() => {
        console.log(`addActionsToData`)
        return listData.map((d) => {
            const date = moment(d.latest_enrollment_timestamp)


            return {
                id: d.id,
                name: d.name,
                description: d.description,
                location_count: d.location_count,
                ytd_enrollment_count: d.ytd_enrollment_count,
                latest_enrollment_timestamp: date.toDate()
            };
        });
    }, [listData])

    return (
        <>
            <Layout>
                <Heading
                    title="CBOs"
                    subtitle="organizations"
                    description="Listing out your community-based organizations."
                    btn_text='Add CBO'
                    onClick={addCBO}
                />

                {isEditing &&
                    <CBOModal
                        title='Edit CBO'
                        initialData={CBOData}
                        onClose={exitCboModal}
                        onSaveAndQuit={updateCBOData}
                        buttonLabel='Save'
                    />
                }

                {(isAdding && !isEditing) &&
                    <CBOModal
                        title='Add CBO'
                        onClose={exitCboModal}
                        onSaveAndQuit={createCBO}
                        buttonLabel='Add'
                    />
                }

                {isLoading &&
                    <WaitModal message={loadReason}/>
                }

                <CBOSearch onSearch={search} />
                <CBOActions data={listData} runBulkAction={runBulkAction}/>
                <CBOSearchTable data={coolData} bulkActionRef={runBulkAction} editCbo={editCbo} deleteCBO={deleteCBO} addNotification={addNotification} refreshData={() => search(mostRecentSearchConditions)}/>
            </Layout>
            {notificationIsShow &&
                ReactDOM.createPortal(
                <Notification
                    status={notificationStatus}
                    text={notificationText}
                    btn_text={notificationBtnText}
                    onClose={() => removeNotification()}
                />,
                document.querySelector("body")
            )}
        </>
    );
}

export default CBOs;
